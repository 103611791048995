<template>
<b-overlay rounded="sm" :show="loading">
  <AuthWrapper>
    <template #card>
      <div class="text-center">
        <div>
          <h1>Phone Number Verification</h1>
        </div>
        <div>
          <p>Kindly enter the OTP sent to {{ getUserMaskedPhoneNumber }} </p>
        </div>
      </div>
      <validation-observer ref="loginForm" #default="{invalid}">
        <b-form @submit.prevent="confirmPhone">
          <!--                username -->
          <b-form-group
              label="Verification Code"
              label-for="mfa"
          >
            <validation-provider
                #default="{ errors }"
                name="mfa"
                vid="mfa"
                rules="required"
            >
              <div class="exxtra-input">
                <input id="login-username" v-model="code" type="number"
                       placeholder="******"
                       style="letter-spacing: 24px; font-size: 20px"
                >
              </div>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>


          <div>
            <b-button
                class="py-1"
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
            >
              Verify
            </b-button>
          </div>
          <div class="text-center pt-1">
            <div class="">
              <p v-if="isResendOTPEnabled" class="font-weight-bold border border-bottom" @click="resendPhoneVerificationCode"> Resend Code
              </p>
            </div>

            <div v-if="!isResendOTPEnabled">
              <p>You can resend verification code after <span class="text-success">{{ timerString }}</span></p>
            </div>




          </div>

          <div class="pt-1">
            <p class="text-center m-0 p-0 cursor-pointer" @click="logout">
              <feather-icon icon="LogOutIcon" />
              Logout
            </p>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </AuthWrapper>
</b-overlay>
</template>

<script>
import AuthWrapper from "@core/components/auth-wrapper/AuthWrapper.vue";
import { maskPhoneNumber } from "@core/utils/utils";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import TurtleRentLogo from '@core/layouts/components/Logo.vue';
import BottomDesign from '@/assets/svg/BottomDesign.svg';
import {
  BOverlay, BAlert, BCard, BCardTitle, BCardText, BForm, BFormGroup, BInputGroup, BInputGroupAppend, BLink, BFormInput, BButton,
} from 'bootstrap-vue'
import { required } from '@validations'
import { get } from "lodash"
import { fromUnixTime, differenceInSeconds } from "date-fns";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import CodeInput from "vue-verification-code-input";

export default {
  components: {
    AuthWrapper,
    BottomDesign,
    TurtleRentLogo,
    BOverlay,
    BAlert,
    BCard,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    CodeInput
  },
  data() {
    return {
      loading: false,
      error: '',
      code: '',
      timerString: '00:00',
      // validation
      required,
    }
  },
  computed: {
    userData() {
      return this.$store.getters[`auth/userData`];
    },
    getUserMaskedPhoneNumber(){
      // console.log('userdata', get(this.userData, 'phone'));
      return maskPhoneNumber(get(this.userData, 'phone', "0248801964"));
    },
    isResendOTPEnabled() {
      return this.timerString === "00:00";
    }
  },
  created(){
    this.resendPhoneVerificationCode(false)
    .then(() => this.updateTimeRemaining())
  },
  beforeDestroy() {
    if (this.timeDifferenceFromVerificationCodeTimestamp() >= 180) {
      return this.useJwt().authService.clearPhoneVerificationTimestamp()
    }
  },
  methods: {
    async resendPhoneVerificationCode(showErrorMessage = true){
      try {
        this.loading = true;
        this.error = "";
        const response = await this.useJwt().authService.requestPhoneVerification();

        const { data } = response.data;
        if (data.sms_verification_code_timestamp) {
          this.useJwt().authService.setPhoneVerificationTimestamp(data.sms_verification_code_timestamp);
        }
        if (data.phone_verification_token) {
          this.useJwt().authService.setPhoneVerificationToken(data.phone_verification_token);
        }
        this.updateTimeRemaining();
      } catch (error){
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.error = error_message

        if (showErrorMessage) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error!',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error_message
            },
          });
        }
        this.updateTimeRemaining();
      } finally {
        this.loading = false;
      }
    },
    async confirmPhone() {
      try {
        this.loading = true;
        this.error = "";

        await this.useJwt().authService.confirmPhoneVerification({
          otp_code: this.code
        })

        this.$router.replace('/')
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Phone number confirmed successfully',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {});
      } catch (error){
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.error = error_message

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    startTimer(duration) {
      let timer = duration;
      let minutes;
      let seconds;

      const interval = setInterval(() => {
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        // eslint-disable-next-line no-plusplus
        if (--timer < 0) {
          clearInterval(interval);
        }

        this.timerString = minutes + ":" + seconds;
      }, 1000);
    },
    getUserVerificationCodeTimestamp() {
      return this.useJwt().authService.getPhoneVerificationTimestamp() || 0;
    },
    timeDifferenceFromVerificationCodeTimestamp() {
      const timestampDate = fromUnixTime(this.getUserVerificationCodeTimestamp())
      const timeDifferenceInSeconds = differenceInSeconds(new Date(), timestampDate)
      return timeDifferenceInSeconds;
    },
    updateTimeRemaining() {
      const timeDifference = this.timeDifferenceFromVerificationCodeTimestamp();
      let countdownSeconds = (60 * 3) - timeDifference;
      if (timeDifference >= 180) {
        countdownSeconds = 0
      }
      this.startTimer(countdownSeconds);
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
